import React, { useEffect } from 'react';
import Slider from 'react-slick';
import HeroSection from 'components/HeroSection';
import './heroSectionCarousel.scss';

const HeroSectionCarousel = ({ heroSections = [], isTextWithOrPrefix }) => {
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  return (
    <div className="hero-section-carousel" id="my-component">
      <div>
        <Slider {...settings}>
          {heroSections?.length &&
            heroSections.map(
              ({ title, description, image, callToAction }, index) => (
                <HeroSection
                  key={`home-section-carousel_${index}`}
                  image={image}
                  title={title}
                  callToActions={callToAction}
                  description={description?.childMarkdownRemark?.html}
                  isTextWithOrPrefix={isTextWithOrPrefix}
                />
              )
            )}
        </Slider>
      </div>
    </div>
  );
};

export default HeroSectionCarousel;
