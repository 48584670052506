import React from 'react';
import useStaticQueryGetPrivateSales from 'services/graphql/queries/PrivateSales';
import TabsVehiclesList from 'features/TabsVehiclesList';

const PrivateSales = () => {
  const { privateSalesList } = useStaticQueryGetPrivateSales();
  const soldItems =
    privateSalesList?.length && privateSalesList.filter(item => item.salePrice);
  const availableItems =
    privateSalesList?.length &&
    privateSalesList.filter(item => !item.salePrice);

  return (
    <TabsVehiclesList
      tabs={[
        {
          tabName: 'Available',
          tabList: availableItems,
          noDataMessage: 'There are no Private Sales available at this time.',
        },
        {
          tabName: 'Recently Sold',
          tabList: soldItems,
          noDataMessage:
            'There are no Private Sales recently sold at this time.',
        },
      ]}
      type={`privateSales`}
      defaultTap={availableItems?.length === 0 ? 'Recently Sold' : 'Available'}
    />
  );
};

export default PrivateSales;
