import React, { useState, Fragment } from 'react';
import _ from 'lodash';
import {
  getAuction,
  getPrice,
  getTag,
  getContentfulItemTitle,
} from 'shared/helpers/vehicles';
import VehicleCard from 'components/VehicleCard';
import Filters from '../filters';
import { combineCloudinaryWithImages } from 'utils/image';
import styles from './vehicles.module.scss';

const Vehicles = ({ tabs = [], type, defaultTap }) => {
  const [vehicleType, setVehicleType] = useState(
    defaultTap ? defaultTap : tabs?.length > 0 ? tabs[0]?.['tabName'] : ''
  );

  const items = _.find(tabs, ['tabName', vehicleType])?.tabList;
  const getPriceWithCurrency = vehicle => {
    switch (type) {
      case 'availableLots':
        const currency = vehicle?.auction?.currency;
        return getPrice(vehicle, currency);

      case 'privateSales':
        return getPrice(vehicle, vehicle?.currency);
      default:
        return getPrice(vehicle);
    }
  };

  return (
    <Fragment>
      <Filters
        vehicleType={vehicleType}
        tabs={tabs}
        setVehicleType={type => setVehicleType(type)}
      />
      <div className={styles.vehiclesContainer}>
        <div className={styles.vehiclesWrapper}>
          {items?.length > 0 &&
            items.map((vehicle, index) => (
              <VehicleCard
                key={`vehicleCard_${index}`}
                fluidImages={combineCloudinaryWithImages(vehicle, 500, 3).slice(
                  0,
                  3
                )}
                tag={getTag(vehicle)}
                makeModel={getContentfulItemTitle(vehicle)}
                auction={getAuction(vehicle)}
                price={getPriceWithCurrency(vehicle)}
                slug={vehicle.slug}
                arrowCircle={false}
                showDotsOnHover={true}
                salesForceId={vehicle?.salesForceId}
                className={styles.vehicle}
                onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
                auctionSlug={
                  vehicle.auction?.webpage__auction
                    ? vehicle.auction?.webpage__auction[0].slug
                    : ''
                }
              />
            ))}
        </div>
        {(!items || items?.length === 0) && (
          <p className="noResultsMsg">
            {_.find(tabs, ['tabName', vehicleType])?.noDataMessage ||
              'No results found'}
          </p>
        )}
      </div>
    </Fragment>
  );
};

export default Vehicles;
